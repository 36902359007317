import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'

import './style.css'

function Navigation() {
    return (
        <Navbar className="justify-content-center" variant="light" expand="md" sticky="top">
            <Navbar.Brand href="/">
                <h2>SCALES DEVELOPERS</h2>
            </Navbar.Brand>
        </Navbar>

    )
}

export default Navigation