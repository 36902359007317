import React from 'react'
import { FaHeart, FaRegCopyright } from 'react-icons/fa';
import './style.css'

function Footer() {
    return (
        <div className="footer" fixed="bottom">
            {/* <hr className="separator"></hr> */}
            made with <FaHeart /> by <a className="devLink" href="https://lexi.scalesdev.com" target="_blank" rel="noopener noreferrer">lexi jack</a><br />
            <FaRegCopyright /> 2022
        </div>
    )
}

export default Footer