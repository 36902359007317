import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Footer from '../../Components/Footer'

import Navigation from '../../Components/Navigation'
import './style.css'

function Root() {

    return (
        <div className="viewPage">
            <Navigation />
            <Container className="pageContent">
                <Row xs={1} md={2}>
                    <Col>
                        <a href="https://lexi.scalesdev.com">
                            <Image src="./images/lexi.gif" fluid />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://michael.scalesdev.com">
                            <Image src="./images/kele.gif" fluid />
                        </a>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default Root